import { type ReactNode, useState, useRef, useEffect, useMemo } from "react";
import { Socket } from "socket.io-client";
import { getFiles, getFilesFromConversation } from "../../services/File/file";
import type { ConversationType, Message } from "../../types/chat";
import { FileType } from "../../types/file";
import { useUser } from "../../utils/store/userStore";
import { useSocket } from "../Socket";
import { Messages, FileData, ChatContext } from "./ChatContext";

export const MessageEvent = {
  RECEIVE: "receive",
  PING: "ping",
  PONG: "pong",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  NEW_DOCUMENT: "new-document",
  INIT: "init",
  NOTIFICATION: "notification",
  REACTION: "react",
  ERROR: "error",
  JOIN: "join",
  MESSAGES_LOADED: `messagesLoaded`,
  CONVERSATIONS_LOADED: `conversationsLoaded`,
  SEND: `send`,
  LOAD_MESSAGES: `loadMessages`,
  LOAD_CONVERSATIONS: `loadConversations`,
  GET_CONVERSATION_BY_ID: `getConversationById`,
  EXPERT: {
    JOIN: `join/expert`,
    SEND: `send/expert`,
    LOAD_MORE: `loadMessagesBeforeId/expert`
  },
  REACT: `react`
};

export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [conversations, setConversations] = useState<ConversationType[]>();
  const [activeConversation, setActiveConversation] =
    useState<ConversationType>();
  const [userId, setUserId] = useState<number | undefined>();
  const [activeUsers, setActiveUsers] = useState<{ users: number[] }>({
    users: []
  });
  const [documents, setDocuments] = useState<FileType[]>([]);
  const [messages, setMessages] = useState<Messages>();
  const [newDocs, setNewDocs] = useState<FileType[]>([]);
  const { socket } = useSocket();
  const user = useUser((state) => state.userdata);
  // const [isFetching, setIsFetching] = useState<boolean>(false);
  const isFetching = useRef<boolean>(false);
  const [isExpertMode, setIsExpertMode] = useState<boolean>(false);

  const page = useRef<number>(0);
  // const [page, setPage] = useState<number>(0);
  const lastMessageId = useRef<number | null>(null);
  const lastConversationId = useRef<number | null>(null);

  const fetchConversations = async () => {
    try {
      const event = MessageEvent.LOAD_CONVERSATIONS;
      const data = {
        beforeId: lastConversationId.current || 0,
        expert: isExpertMode
      };
      // console.log("Fetching conversations", data);

      socket?.emit(event, data, (response: ConversationType[]) => {
        setConversations(response);
      });
    } catch (error) {
      console.error("Failed to fetch conversations:", error);
      setConversations(undefined);
    }
  };

  const getConversationById = async (conversationId?: number) => {
    if (!conversationId) return;
    try {
      const event = MessageEvent.GET_CONVERSATION_BY_ID;
      const data = {
        conversationId
      };
      socket?.emit(event, data, (response: ConversationType[]) => {
        if (response && response.length > 0) {
          setActiveConversation(response[0]);
        }
      });
    } catch (error) {
      console.error("Failed to fetch conversation:", error);
      setActiveConversation(undefined);
    }
  };

  const loadMessagesBeforeId = async (
    limit: number = 20,
    before_id?: number,
    switchedConversation: boolean = false
  ) => {
    console.log(
      "Loading messages before id",
      page.current,
      switchedConversation,
      messages,
      isFetching.current
    );
    if (!activeConversation) return 0;
    if (page.current === -1) return 0;
    if (!switchedConversation && messages && messages?.messages.length < 20)
      return 0;
    if (isFetching.current) return 0;
    isFetching.current = true;
    // setIsFetching(true);
    const event = MessageEvent.LOAD_MESSAGES;

    const data = {
      conversationId: activeConversation?.id,
      limit: limit,
      ...((before_id ?? lastMessageId.current)
        ? { beforeId: before_id ?? lastMessageId.current }
        : {})
    };
    socket?.emit(event, data, (newMessages: Message[]) => {
      isFetching.current = false;
      if (newMessages.length < 20) {
        page.current = -1;
      }
      const lastMessageIdBeforeReceive = lastMessageId.current;
      setMessages((prevMessages) => ({
        messages: [...newMessages, ...(prevMessages?.messages || [])],
        shouldAutoScroll: lastMessageIdBeforeReceive === null
      }));
      lastMessageId.current = newMessages[0]?.messageId ?? null;
    });
  };

  const emitSendEvent = (data: any, timeout = 8000): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (!socket) {
        return reject(new Error("Socket is not connected."));
      }

      const timer = setTimeout(() => {
        reject(new Error("Response timed out."));
      }, timeout);

      socket.emit(MessageEvent.SEND, data, (response: any) => {
        clearTimeout(timer);
        if (response && response.error) {
          reject(response.error);
        } else {
          resolve(response);
        }
      });
    });
  };

  const createConversation = async (
    targetId: number,
    jobId: number,
    content: string,
    files: FileData[]
  ) => {
    const data = {
      targetId,
      jobId,
      message: {
        content,
        files: files.map(({ fileName, fileContent }) => ({
          fileName,
          fileContent
        }))
      }
    };
    return await emitSendEvent(data);
  };

  const sendMessage = async (content: string, files: File[]) => {
    if (!socket || !activeConversation) return;
    try {
      // if (files.length !== 0) {
      //   const formData = new FormData();

      //   if (activeConversation.id !== undefined) {
      //     formData.append("conversationId", activeConversation.id.toString());
      //   }

      //   formData.append("content", content);
      //   // Create the message object
      //   // const message: Partial<Message> = { content };

      //   // Serialize the message object to JSON
      //   // formData.append("message", JSON.stringify(message));

      //   // Append each file separately
      //   // files.forEach((file) => {
      //   //   formData.append("files", file);
      //   // });
      //   formData.append("files", files[0]);

      //   // Now, send the FormData using fetch or your preferred method
      //   const response = await fetchApi("/chat/send", "POST", formData);
      //   console.log(response);
      // } else {

      const promises = files.map((file) => {
        return new Promise<FileData>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e.target?.result) {
              // Extract Base64 string by removing the Data URL prefix
              const base64String = (e.target.result as string).split(",")[1];
              resolve({
                fileName: file.name,
                fileContent: base64String, // Only Base64 content
                fileType: file.type
              });
            } else {
              reject(new Error("File reading failed"));
            }
          };
          reader.onerror = () => {
            reject(new Error("Error reading file"));
          };
          reader.readAsDataURL(file); // Read as Data URL
        });
      });

      const filesData = await Promise.all(promises)
        .then(async (filesData) => {
          const data = {
            conversationId: activeConversation.id,
            message: {
              content,
              files: filesData
            }
          };
          await emitSendEvent(data);
        })
        .catch((error) => {
          console.error("Error reading files:", error);
        });
      // }
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const fetchDocuments = async (
    conversationId?: number | undefined,
    roleName?: string | undefined
  ) => {
    if (conversationId && roleName) {
      const response = await getFilesFromConversation(conversationId, roleName);
      if (response) setDocuments(response);
    } else {
      const response = await getFiles();
      // console.log("Response getFiles", response);
      if (response) setDocuments(response);
    }
  };

  const setupSocketListeners = (
    socket: Socket,
    setMessages: React.Dispatch<React.SetStateAction<Messages | undefined>>
  ) => {
    const handleReceive = (message: Message) => {
      setMessages((prevMessages) => ({
        messages: [...(prevMessages?.messages || []), message],
        shouldAutoScroll: true
      }));
    };

    const handleReact = (updatedMessage: Message) => {
      setMessages((prevMessages) => {
        const updatedMessages = (prevMessages?.messages || []).map((message) =>
          message.messageId === updatedMessage.messageId
            ? { ...message, reactions: updatedMessage.reactions }
            : message
        );
        return {
          messages: updatedMessages,
          shouldAutoScroll: false
        };
      });
    };
    const handleNewDocument = (data: any) => {
      setNewDocs([data.attachment]); // Mettez à jour les nouveaux documents
      setDocuments((prev) => [data.attachment, ...prev]);
    };

    const handleError = (error: any) => {
      console.error(error);
    };

    socket.on(MessageEvent.RECEIVE, handleReceive);
    socket.on(MessageEvent.REACT, handleReact);
    socket.on(MessageEvent.NEW_DOCUMENT, handleNewDocument);
    socket.on(MessageEvent.ERROR, handleError);

    return () => {
      socket.off(MessageEvent.RECEIVE, handleReceive);
      socket.off(MessageEvent.REACT, handleReact);
      socket.off(MessageEvent.NEW_DOCUMENT, handleNewDocument);
      socket.off(MessageEvent.ERROR, handleError);
    };
  };

  const emitJoinEvent = (
    socket: Socket,
    userRoleName: string | undefined,
    conversationId: number,
    isExpertMode: boolean
  ) => {
    socket.emit(
      MessageEvent.JOIN,
      { conversationId, expert: isExpertMode },
      (response: any) => {
        setActiveUsers(response);
      }
    );
  };

  useEffect(() => {
    // if (activeConversation && activeConversation.id) {
    //   console.log("activeConversation", activeConversation);
    // }
    // setPage(0);
    page.current = 0;
  }, [activeConversation]);

  // Handle socket events
  useEffect(() => {
    // console.log("Socket", socket, user?.roleName, activeConversation);
    if (socket) {
      // console.log("Joining conversation", activeConversation?.id);
      const cleanup = setupSocketListeners(socket, setMessages);
      return cleanup;
    }
  }, [socket, user?.roleName, setMessages]);

  // Fetch messages when the active conversation changes, could cache messages and documents
  useEffect(() => {
    console.log("Active conversation changed", activeConversation);
    setMessages(undefined);
    lastMessageId.current = null;
    if (socket && activeConversation && activeConversation.id) {
      fetchConversations();
      emitJoinEvent(
        socket,
        user?.roleName,
        activeConversation?.id,
        isExpertMode
      );
      loadMessagesBeforeId(20, 0, true);
      fetchDocuments(activeConversation?.id, user?.roleName);
    } else if (socket) {
      fetchDocuments();
    } else {
      setDocuments([]);
    }
  }, [activeConversation, isExpertMode, user?.roleName]);

  const contextValue = useMemo(
    () => ({
      conversations,
      userId,
      activeUsers,
      isExpertMode,
      messages,
      sendMessage,
      createConversation,
      activeConversation,
      setActiveConversation,
      getConversationById,
      loadMessagesBeforeId,
      page: page.current,
      documents,
      newDocs,
      setNewDocs,
      isFetching: isFetching.current,
      fetchConversations,
      setIsExpertMode
    }),
    [
      conversations,
      userId,
      activeUsers,
      isExpertMode,
      messages,
      sendMessage,
      createConversation,
      activeConversation,
      loadMessagesBeforeId,
      getConversationById,
      page,
      documents,
      newDocs,
      setNewDocs,
      isFetching,
      fetchConversations,
      setIsExpertMode
    ]
  );

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};
