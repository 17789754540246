// Require
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Icons
import { User } from "iconsax-react";

// Components
import { BlocTitle } from "../../../../../../components/Bloc/Title";
import Chat from "../../../../../../components/Chat/Chat";
import { LastDocumentsStatic } from "../../../../../../components/Documents/LastDocuments";
import Advancement from "../../../../../../components/Jobs/Advancement";
import JobResume from "../../../../../../components/Jobs/Details/JobResume";
import Proposal from "../../../../../../components/Jobs/Proposal";
import Remember from "../../../../../../components/Jobs/Details/Remember";
import Suggestion from "../../../../../../components/Jobs/Suggestion";
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";
import AdminTools from "../../../../../../components/Admin/Tools";

// Services
import { getJobDetails } from "../../../../../../services/Job/job";

// Types
import type { JobDetailsType } from "../../../../../../types/job";

// Helpers
import { capitalizeWords } from "../../../../../../utils/helpers/capitalizeWords";

// Utils
import { useUser } from "../../../../../../utils/store/userStore";
import { useChat } from "../../../../../../context/Chat/ChatContext";

const Job = () => {
  const { id } = useParams();

  const user = useUser((state) => state.userdata);

  const { getConversationById } = useChat();

  const _ADMIN_MODE = user?.roleName === "admin";

  const [data, setData] = useState<JobDetailsType>();

  useEffect(() => {
    const fetch = async () => {
      if (user) {
        const response: JobDetailsType = await getJobDetails(id);
        if (response && response.conversationId) {
          setData(response);
          getConversationById(response.conversationId);
        }
      }
    };

    fetch();
  }, [id]);

  if (data) {
    return (
      <div className="flex flex-col gap-8">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row gap-6">
            <Title type="page">{data?.title}</Title>
            <div className="flex flex-row items-center gap-2">
              <img
                src={data.owner.image}
                alt="pic"
                className="h-[30px] w-[30px] rounded-lg"
              />
              <Text>
                {capitalizeWords(
                  `${data?.owner.firstName} ${data?.owner.lastName}`
                )}
              </Text>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-8">
          <div className="flex w-[calc(100%-530px)] flex-col gap-8">
            <Advancement step={data.status.index} />
            <Remember data={data} />
            {data.status.index > 2 && <Chat ldStatic={true} />}
          </div>
          <div className="flex h-fit w-[500px] min-w-[500px] max-w-[500px] flex-col gap-8">
            {_ADMIN_MODE && <AdminTools />}

            {data.status.index < 3 && (
              <Suggestion data={data.studentSelected ?? []} />
            )}

            {data.status.index > 2 && (
              <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
                <BlocTitle>
                  <User size={18} />
                  <Title type="bloc">Etudiant embauché pour la mission</Title>
                </BlocTitle>
                <div className="rounded-lg border">
                  {/* <StudentHorizontalCardExtraMin /> */}
                </div>
                {data.status.index === 4 && (
                  <Button type="full">Laisser un avis à l'étudiant</Button>
                )}
              </div>
            )}
            {data.status.index > 2 && (
              <>
                <JobResume data={data} />
                <LastDocumentsStatic attachmentsType="conversationId" />
              </>
            )}
          </div>
        </div>
        {data.status.index > 0 && data.status.index < 3 && (
          <div className="flex h-fit flex-col flex-wrap gap-8 rounded-lg border p-4 shadow-lg">
            <Proposal alreadyDisplay={data.studentSelected ?? []} />
          </div>
        )}
      </div>
    );
  }

  return <p>Loading</p>;
};

export default Job;
